var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-card",
            { staticClass: "cardClassDetailInfo", attrs: { noHeader: true } },
            [
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-label-text", {
                      attrs: { title: "사업장", value: _vm.param.plantName },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-label-text", {
                      attrs: {
                        title: "설비유형",
                        value: _vm.param.equipmentTypeName,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-label-text", {
                      attrs: { title: "설비Tag", value: _vm.param.equipmentNo },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-label-text", {
                      attrs: {
                        title: "설비명",
                        value: _vm.param.equipmentName,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c("c-table", {
            ref: "grid",
            attrs: {
              title: "정비이력",
              columns: _vm.grid.columns,
              data: _vm.grid.data,
              gridHeight: _vm.grid.height,
            },
            on: { linkClick: _vm.linkClick },
            scopedSlots: _vm._u([
              {
                key: "customArea",
                fn: function ({ props, col }) {
                  return [
                    col.name === "woWorkPlanStepCd"
                      ? [
                          _c(
                            "q-chip",
                            {
                              class:
                                props.row.woWorkPlanStepCd == "WWPSC00000"
                                  ? "blinking"
                                  : "",
                              attrs: {
                                color: _vm.getColors(
                                  props.row.woWorkPlanStepCd
                                ),
                                "text-color": "white",
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(props.row.woWorkPlanStepName) + " "
                              ),
                            ]
                          ),
                        ]
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c("c-table", {
            ref: "grid2",
            attrs: {
              title: "점검이력",
              columns: _vm.grid2.columns,
              data: _vm.grid2.data,
              gridHeight: _vm.grid2.height,
            },
            scopedSlots: _vm._u([
              {
                key: "customArea",
                fn: function ({ props }) {
                  return [
                    props.row["checkStatusCd"]
                      ? [
                          _c(
                            "q-chip",
                            {
                              class:
                                props.row.checkStatusCd === "MCSC000020"
                                  ? "blinking"
                                  : null,
                              attrs: {
                                color: _vm.setTagColor(props.row.checkStatusCd),
                                clickable: true,
                                "text-color": "white",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.btnClicked(props.row)
                                },
                              },
                            },
                            [
                              props.row.checkStatusCd === "MCSC000020"
                                ? _c("q-avatar", {
                                    attrs: {
                                      icon: "alarm",
                                      color: "red",
                                      "text-color": "white",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.setTagName(props.row.checkStatusCd)
                                  ) +
                                  " "
                              ),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }