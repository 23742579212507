<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card class="cardClassDetailInfo" :noHeader="true">
        <template slot="card-detail">
          <div class="col-3">
            <c-label-text title="사업장" :value="param.plantName"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="설비유형" :value="param.equipmentTypeName"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="설비Tag" :value="param.equipmentNo"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="설비명" :value="param.equipmentName"></c-label-text>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="grid"
        title="정비이력"
        :columns="grid.columns"
        :data="grid.data"
        :gridHeight="grid.height"
        @linkClick="linkClick"
      >
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name==='woWorkPlanStepCd'">
            <q-chip
              :color="getColors(props.row.woWorkPlanStepCd)"
              :class="props.row.woWorkPlanStepCd == 'WWPSC00000' ? 'blinking' : ''"
              text-color="white">
              {{props.row.woWorkPlanStepName}}
            </q-chip>
          </template>
        </template>
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="grid2"
        title="점검이력"
        :columns="grid2.columns"
        :data="grid2.data"
        :gridHeight="grid2.height"
      >
        <template v-slot:customArea="{ props }">
          <template v-if="props.row['checkStatusCd']">
            <q-chip
              :color="setTagColor(props.row.checkStatusCd)"
              :clickable="true"
              :class="props.row.checkStatusCd ==='MCSC000020' ? 'blinking' : null"
              text-color="white"
              @click="btnClicked(props.row)">
              <q-avatar v-if="props.row.checkStatusCd==='MCSC000020'" icon="alarm" color="red" text-color="white" />
              {{setTagName(props.row.checkStatusCd)}}
            </q-chip>
          </template>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'equipment-inspect',
  props: {
    param: {
      type: Object,
      default: () => ({
        equipmentCd: '',
        equipmentNo: '',
        plantName: '',
        plantCd: '',
        processCds: [],
        equipmentTypeName: '',
        equipmentName: '',
      }),
    },
    editable: {
      type: Boolean,
      default: true,
    },
    revEquipmentCd: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        width: '90%',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      listUrl: '',
      listUrl2: '',
      searchParam: {
        equipmentCd: '',
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'woWorkPlanStepCd',
            field: 'woWorkPlanStepCd',
            label: '진행상태',
            align: 'center',
            type: 'custom',
            style: 'width:100px',
          },
          {
            name: 'workPlanName',
            field: 'workPlanName',
            label: '작업명',
            align: 'left',
            sortable: true,
            type: 'link'
          },
          {
            name: 'funcLocationName',
            field: 'funcLocationName',
            label: '위치',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            label: '설비',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'workDeptName',
            field: 'workDeptName',
            label: '작업부서',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'woWorkTypeName',
            field: 'woWorkTypeName',
            label: '작업유형',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'planStartDt',
            field: 'planStartDt',
            label: '목표작업일',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'planDtm',
            field: 'planDtm',
            label: '목표작업시간',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'reqDeptName',
            field: 'reqDeptName',
            label: '요청부서',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'reqDt',
            field: 'reqDt',
            label: '정비요청일',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
        ],
        data: [],
        height: '350px'
      },
      grid2: {
        columns: [
          {
            name: "plantName",
            field: "plantName",
            style: 'width:100px',
            label: "사업장",
            align: "center",
            sortable: false,
          },
          {
            name: "checkStatusCd",
            field: "checkStatusCd",
            label: "진행상태",
            style: 'width:100px',
            type: 'custom',
            align: "center",
            sortable: false,
          },
          {
            name: "checkScheduleDate",
            field: "checkScheduleDate",
            label: "점검예정일",
            style: 'width:100px',
            align: "center",
            sortable: false,
          },
          {
            name: "checkScheduleUserName",
            field: "checkScheduleUserName",
            style: 'width:100px',
            label: "점검예정자",
            align: "center",
            sortable: false,
          },
          {
            name: "checkDeptName",
            field: "checkDeptName",
            style: 'width:100px',
            label: "점검부서",
            align: "center",
            sortable: false,
          },
          {
            name: "checkDate",
            field: "checkDate",
            style: 'width:100px',
            label: "점검완료일",
            align: "center",
            sortable: false,
          },
          {
            name: "checkResultName",
            field: "checkResultName",
            style: 'width:80px',
            label: "점검결과",
            align: "center",
            sortable: false,
          },
          {
            name: "equipmentCheckKindNm",
            field: "equipmentCheckKindNm",
            label: "점검유형",
            style: 'width:100px',
            align: "center",
            sortable: false,
          },
          {
            name: "inspectionCycleName",
            field: "inspectionCycleName",
            label: "점검주기",
            style: 'width:80px',
            align: "center",
            sortable: false,
          },
          {
            name: "relatedLawsName",
            field: "relatedLawsName",
            label: "관련법규",
            style: 'width:250px',
            align: "left",
            sortable: false,
          },
          {
            name: "noPlanName",
            field: "noPlanName",
            label: "계획/무계획",
            style: 'width:80px',
            align: "center",
            sortable: false,
          },
        ],
        data: [],
        height: '350px'
      },
      colorItems: [
        { stepperMstCd: 'MCSC000005', stepperMstNm: '계획수립', colorClass: 'blue' },
        { stepperMstCd: 'MCSC000010', stepperMstNm: '점검중', colorClass: 'orange' },
        { stepperMstCd: 'MCSC000015', stepperMstNm: '점검완료', colorClass: 'green' },
        { stepperMstCd: 'MCSC000020', stepperMstNm: '지연', colorClass: 'red' },
      ],
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
    'revEquipmentCd'() {
      this.getList();
      this.getList2();
    },
  },
  methods: {
    init() {
      // this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.wod.workorder.plan.list.url;
      this.listUrl2 = selectConfig.sop.min.equipment.plan.list.url;
      this.getList();
      this.getList2();
    },
    getList() {
      if (this.param.equipmentCd) {
        this.$http.url = this.listUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          equipmentCd: this.revEquipmentCd === this.param.equipmentCd ? this.param.equipmentCd : this.revEquipmentCd
        };
        this.$http.request((_result) => {
          this.grid.data = _result.data;
        },);
      }
    },
    getList2() {
      if (this.param.equipmentCd) {
        // 전체조회
        this.$http.url = this.listUrl2;
        this.$http.type = 'GET';
        this.$http.param = {
          equipmentCd: this.revEquipmentCd === this.param.equipmentCd ? this.param.equipmentCd : this.revEquipmentCd
        };
        this.$http.request((_result) => {
          this.grid2.data = _result.data;
        },);
      }
    },
    setTagColor(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { stepperMstCd: data })
        return color ? color.attrVal1 || color.colorClass : '';
      } else {
        return ''
      }
    },
    setTagName(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { stepperMstCd: data })
        return color ? color.codeName || color.stepperMstNm : '';
      } else {
        return ''
      }
    },
    linkClick(row) {
      this.openPop(row);
    },
    openPop(row) {
      this.popupOptions.title = '작업지시(계획) 상세';
      this.popupOptions.param = {
        workPlanId: row ? row.workPlanId : '',
        plantCd: row ? row.plantCd : '',
        woWorkPlanStepCd: row ? row.woWorkPlanStepCd : 'WWPSC00001',
        woRequestId: row ? row.woRequestId : '',
        copyFlag: row ? row.copyFlag : 'N',
        firstCopy: row ? (row.firstCopy ? row.firstCopy : false) : false,
      };
      this.popupOptions.target = () => import(`${'@/pages/wod/plan/workOrderPlanDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    /* eslint-disable no-unused-vars */ 
    btnClicked(row) {
      this.popupOptions.title = '설비점검결과 상세'; // 설비점검계획 상세
      this.popupOptions.param = {
        minEquipmentCheckId: row.minEquipmentCheckId ? row.minEquipmentCheckId : '',
        title: '설비점검결과',
        selectData: [],
        noPlan: 'N',
      };
      this.popupOptions.target = () => import(`${"@/pages/sop/mim/inspection/equipmentResultDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    getColors(_code) {
      var returnColor = '';
      switch(_code) {
        case 'WWPSC00000': // 정비요청
          returnColor = 'grey-6';
          break;
        case 'WWPSC00001': // 작업계획중
          returnColor = 'orange';
          break;
        case 'WWPSC00002': // 작업계획 승인중
          returnColor = 'blue';
          break;
        case 'WWPSC00003': // 작업진행중
          returnColor = 'deep-purple-6';
          break;
        case 'WWPSC00009': // 작업완료
          returnColor = 'green';
          break;
        case 'WWPSC99999': // 작업취소
        case 'WWPSC99998': // 작업계획취소
          returnColor = 'red-6';
          break;
      }

      return returnColor;
    },
  }
};
</script>